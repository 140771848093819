import { isMobile } from 'react-device-detect';
import { CSSProperties } from 'styled-components';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import NeonText from './common/NeonText';


const fontSizeSubTitle = isMobile ? '1.3vh' : '2vh';

export function TopBar() {
    return (<Container className='title-box-background'>

        <NeonText text='Alisados by Paula' fontName='Tuesday Night'/>

            <Typography component='h1' align='center' style={{
                borderColor: 'black',
                borderStyle: 'solid',
                borderWidth: '0.3vh',
                bottom: '2vh',
                color: 'black',
                fontWeight: 'bold',
                letterSpacing: '2vh',
                marginLeft: '5vh',
                marginRight: '5vh',
                padding: '2vh',
                position: 'relative',
            }} fontSize={fontSizeSubTitle}>
                HAIRCARE
            </Typography>

    </Container>);
}

