import { Container, Typography } from "@mui/material";
import { BackButton } from "../common/BackButton";
import { CDNUrl } from "../common/config";

const video2 = `${CDNUrl}/Portfolio/video2LOW.mp4`;

export default function PortFolio() {
    return (<Container>
        <BackButton url="/"></BackButton>
        <Typography className="highlight" component="div" fontSize='12px'>
            <h3>PORTAFOLIO</h3>
        </Typography>
        <br />
        <Typography textAlign='justify' style={{ textAlignLast: 'left' }}>
            Me desempeño hace más de 5 años como profesional en el ámbito del cuidado capilar. Mi objetivo es brindar el mejor servicio en tratamientos para el cabello, centrado en la salud y la belleza capilar.
        </Typography>
        <br />
        <Typography textAlign='justify' style={{ textAlignLast: 'left' }}>
        Cuento con conocimientos especializados en tratamientos de queratina, hidratación profunda, botox capilar, alisados permanentes, y restauración capilar. Además, ofrezco servicios personalizados para diferentes tipos de cabello y necesidades específicas, asegurando siempre un resultado óptimo.
        </Typography>
        <br />
        <Typography textAlign='justify' style={{ textAlignLast: 'left' }}>
        Proporciono un espacio adecuado, totalmente personalizado, con servicio a domicilio disponible 24 horas. Mis servicios incluyen tratamientos capilares avanzados, cortes de cabello, coloraciones, diseño de cejas, y más, todos dedicados a resaltar la belleza natural de cada cliente.
        </Typography>
        <br />
        <Typography textAlign='justify' style={{ textAlignLast: 'left' }}>
        Mi enfoque se basa en los fundamentos del cuidado capilar y en lo que puede aportar al bienestar personal y social.
        </Typography>
        <br />
        <br />
        <br />
        <br />

    </Container >)
}