import { Box, Card, CardContent, CardHeader, CardMedia, Container, ImageList, ImageListItem, ImageListItemBar, List, ListItem, Typography, useMediaQuery, useTheme } from "@mui/material";
import { BackButton } from "../common/BackButton";
import ReactPlayer from "react-player/lazy";
import { CDNUrl } from "../common/config";
import { Margin } from "@mui/icons-material";
import { CSSProperties } from "react";
import AnimatedListItem from "../common/AnimatedListItem";

const videoUrl1 = `${CDNUrl}/keratin/keratin01.mp4`;
const videoUrl2 = `${CDNUrl}/keratin/keratin02.mp4`;
const videoUrl3 = `${CDNUrl}/keratin/keratin03.mp4`;
const videoUrl4 = `${CDNUrl}/keratin/keratin04.mp4`;


const gradientStyle: CSSProperties = {
    backgroundImage: "linear-gradient(45deg, #f8c6c8 0%, #fddde6 100%)",
    backgroundColor: '#ecd86a',
    boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.3)",
    borderImageSlice: "1",
    borderRadius: "10px",
    padding: "5px",
    color: 'black',
    margin: '1vh',
};

export default function KeratinTreatment() {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const services = [
        "HIDRATACIÓN PROFUNDA",
        "REPARACIÓN INTENSIVA",
        "TRATAMIENTO ANTIFRIZZ",
        "NUTRICIÓN CAPILAR",
        "ALISADO CON KERATINA",
        "MASCARILLA FORTIFICANTE",
        "TRATAMIENTO ANTI CAÍDA",
        "BRILLO Y SUAVIDAD",
    ];

    return (
        <Container>
            <BackButton url='/'></BackButton>
            <Typography className="highlight" component="div" fontSize='12px'>
                <h3>KERATINA</h3>
            </Typography>
            <br />

            <Typography textAlign="justify" margin={'2vh'}>
                La aplicación de keratina es un tratamiento avanzado diseñado para restaurar y fortalecer el cabello desde la raíz hasta a las puntas. Este servicio elimina el frizz, aporta brillo y suavidad, y deja el cabello liso y manejable por semanas con una duración de 4 a 6 meses. Ideal para quienes buscan un cabello saludable y de aspecto impecable.
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : "row", justifyContent: 'space-around' }}>
                <Card sx={{ margin: '1vh', maxWidth: '50vh' }}>
                    <CardMedia sx={{ textAlign: 'center', borderRadius: '1vh' }}>
                        <ReactPlayer
                            muted loop={true} volume={0} playing={true}
                            width='auto'
                            height='40vh'
                            url={videoUrl1} />
                    </CardMedia>
                </Card>

                <Card sx={{ margin: '1vh', maxWidth: '50vh' }}>
                    <CardMedia sx={{ textAlign: 'center' }}>
                        <ReactPlayer
                            muted loop={true} volume={0} playing={true}
                            width='auto'
                            height='40vh'
                            url={videoUrl2} />
                    </CardMedia>
                </Card>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : "row", justifyContent: 'space-around' }}>

                <Card sx={{ margin: '1vh', maxWidth: '50vh' }}  >
                    <CardMedia sx={{ textAlign: 'center' }}>
                        <ReactPlayer
                            muted loop={true} volume={0} playing={true}
                            width='auto'
                            height='40vh'
                            url={videoUrl3} />
                    </CardMedia>
                </Card>
                <Card sx={{ margin: '1vh', maxWidth: '50vh' }}>
                    <CardMedia sx={{ textAlign: 'center' }}>
                        <ReactPlayer
                            muted loop={true} volume={0} playing={true}
                            width='auto'
                            height='40vh'
                            url={videoUrl4} />
                    </CardMedia>
                </Card>
            </Box>

            <br />
            <Typography textAlign="justify">
                Transforma tu cabello en una obra de arte con la magia de la keratina. Agenda tu cita hoy y siente la diferencia de un cabello suave, saludable y lleno de vida.
            </Typography>

            <Typography className="highlight" component="div" fontSize='12px'>
                <h3>QUE PUEDES LOGRAR</h3>
            </Typography>
            <br />


            <Container style={{ fontSize: '2vh', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    {services.map((service, index) => (
                        <AnimatedListItem key={index} delay={index * 0.2} >{service} </AnimatedListItem>
                    ))}
                </List>
            </Container>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </Container>
    );

}