import { Box, Container, ThemeProvider, Typography } from "@mui/material";
import theme from './theme';
import BottomBar from "./FooterBar";
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { MainLayout } from "./layout/MainLayout";
import { Appointment } from "./appointment/Appointment";
import { LinkCode } from "./qr-code/LinkCode";
import { ColorRing } from "react-loader-spinner";
import { useEffect, useState } from "react";
import PortFolio from "./port-folio/PortFolio";
import { Courses } from "./courses/Courses";
import UniversityDegrees from "./university-degrees/UniversityDegress";
import BeautyEvents from "./beauty-events/BeautyEvents";
import { isVpn } from "./vpnBlocker/vpn";
import KeratinTreatment from "./keratin/KeratinTreatment";
import HairTreatment from "./hairtreatment/HairTreatment";
import Repolarization from "./repolarization/Repolarization";
import Banner from "./promo/Banner";
import SuccessStory from "./history/SuccessStory";
var FontFaceObserver = require('fontfaceobserver');

const Render = () => {

  return (<ThemeProvider theme={theme}>
    <Router>
      <Routes>
        <Route path="" element={<MainLayout />} />        
        <Route path="citas" element={<Appointment />} />
        <Route path="cursos" element={<Courses />} />
        <Route path="portafolio" element={<PortFolio />} />
        <Route path="keratina" element={<KeratinTreatment />} />
        <Route path="tratamiento" element={<HairTreatment />} />        
        <Route path="grados" element={<UniversityDegrees />} />
        <Route path="repolarizacion" element={<Repolarization />} />
        <Route path="mundo" element={<BeautyEvents />} />
        <Route path="exito" element={<SuccessStory />} />
        <Route path="qr" element={<LinkCode />} />
      </Routes>
      <Banner />
      <BottomBar />
      <Container>
        <Typography textAlign={'center'}>
          alisadosbypaula.com<br />
          2024
        </Typography>
      </Container>
    </Router>
  </ThemeProvider >);
}

const LoadSpinner = () => {
  return (<Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    minHeight="100vh"
  >
    <ColorRing
      visible={true}
      height="80"
      width="80"
      ariaLabel="blocks-loading"
      wrapperStyle={{}}
      wrapperClass="blocks-wrapper"
      colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
    />
  </Box>);
}


const WaitForFonts = (): any => {

  var observers: any = [];
  ['Tuesday Night', 'Amiko'].forEach(function (family) {
    var obs = new FontFaceObserver(family);
    observers.push(obs.load(null, 20000));
  });

  return observers;
}

export default function App() {

  const [isLoading, setLoading] = useState(true);
  const [fontLoading, setFontLoading] = useState(true);
  const [isBlocked, setIsBlocked] = useState(false);

  useEffect(() => {
    async function checkVpn() {
      const response = await fetch('https://api.ipify.org?format=json');
      const { ip } = await response.json();

      const isVpnUser = await isVpn(ip);
      setIsBlocked(isVpnUser);
    }

    checkVpn();
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [fontLoading]);

  Promise.all(WaitForFonts()).then(function (fonts) {
    setFontLoading(false);
  });

  if (isBlocked) {
    return (<Container style={{ color: 'gray', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h1>PaulaAlejandra.com</h1>
      <p>Lo lamento no tienes acceso a esta web</p>
    </Container>
    );
  }
  else {
    var isReady = !isLoading && !fontLoading;
    return (!isReady ? LoadSpinner() : Render());
  }
}