import React from "react";
import { Box, Card, CardActionArea, CardContent, CardMedia, Typography, useMediaQuery, IconButton } from "@mui/material";
import { IMenuItem } from "./IMenuItem";
import MENU from "./data";
import { ButtonLink } from "./ButtonLink";
import { CSSProperties } from "styled-components";
import Container from "@mui/material/Container";
import { TopBar } from "../TopBar";
import { useTheme } from "@mui/material/styles";
import Testimonials from "../customer-opinions/Testimonials";
import QrCodeIcon from '@mui/icons-material/QrCode'; // Importar el ícono de QR
import { useNavigate } from 'react-router-dom'; // Para la navegación
import ServiceSummary from "../service-summary/ServiceSummary";

const containerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    background: 'transparent',
};

function BuildMobileItem(item: IMenuItem, delay: number) {
    return (
        <ButtonLink key={item.id} delay={delay} url={item.to} title={item.title} icon={item.icon} />
    );
}

function BuildDesktopItem(item: IMenuItem, delay: number) {
    return (
        <Card key={item.id} sx={{ margin: '10px', minHeight: '40vh' }}>
            <CardMedia
                component="img"
                height="150vh"
                image={item.img}
                alt={item.title}
            />
            <CardContent sx={{ height: '30vh' }}>
                <Typography sx={{ mb: 1.5, textAlign: 'justify' }} fontSize={"2vh"}>
                    {item.text}
                </Typography>
            </CardContent>
            <CardActionArea component="div" sx={{ textAlign: 'center' }}>
                <ButtonLink delay={delay} url={item.to} title={item.title} icon={item.icon} />
            </CardActionArea>
        </Card>
    );
}

export function MainLayout() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate(); // Hook para navegar

    const boxStyle: CSSProperties = isMobile ?
        {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '30px'
        } :
        {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start'
        };

    // Función para manejar el clic del ícono de QR
    const handleQrClick = () => {
        navigate('/qr'); // Navegar a la ruta /qr
    };

    return (
        <Container style={containerStyle} sx={{ padding: '0vh' }}>
            <TopBar />

            <Box sx={{
                position: 'absolute',
                top: '10px',
                right: '10px',
            }}>
                <IconButton onClick={handleQrClick}>
                    <QrCodeIcon sx={{ fontSize: '24px' }} /> {/* Tamaño ajustado del ícono */}
                </IconButton>
            </Box>

            <Box style={boxStyle}>
                {MENU.map((item: IMenuItem, index) => {
                    if (isMobile) {
                        return BuildMobileItem(item, index * 100);
                    } else {
                        return BuildDesktopItem(item, index * 100);
                    }
                })}
            </Box>

            <Testimonials />
            <ServiceSummary />
            <br />
            <br />
            <br />
        </Container>
    );
}

export default MainLayout;
