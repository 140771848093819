import React from 'react';
import { Card, CardContent, Typography, Grid, CardMedia } from '@mui/material';
import { Img } from 'react-image';
import { CUSTOMER_OPINIONS } from './data';

export interface ITestimonial {
    title: string;
    testimonial: string;
    img: string;  // Asegúrate de que el campo de imagen esté definido
}

const TestimonialCard: React.FC<{ item: ITestimonial }> = ({ item }) => {
    return (
        <Card style={{ margin: '20px', padding: '20px' }}>
            <CardMedia
                sx={{ 
                    height: { xs: '200px', sm: '300px', md: '400px' },  // Altura responsiva
                    width: '100%',  // La imagen ocupa el 100% del ancho
                    objectFit: 'cover',  // Recorta horizontalmente manteniendo la proporción
                    borderRadius: '16px'  // Bordes redondeados
                }}
            >
                <Img 
                    src={item.img} 
                    alt={`Testimonial ${item.title}`} 
                    style={{ 
                        width: '100%', 
                        height: '100%', 
                        objectFit: 'cover',  // La imagen se recorta en horizontal si es necesario
                        borderRadius: '16px'  // Bordes redondeados en la imagen
                    }} 
                />
            </CardMedia>
            <CardContent>
                <Typography variant="h5" component="div">
                    {item.title}
                </Typography>
                <Typography variant="body1" color="textSecondary" component="p" style={{ marginTop: '10px' }}>
                    {item.testimonial}
                </Typography>
            </CardContent>
        </Card>
    );
};

const Testimonials = () => {
    return (
        <Grid container spacing={4}>
            {CUSTOMER_OPINIONS.map((item, index) => (
                <Grid item xs={12} sm={6} key={index}>
                    <TestimonialCard item={item} />
                </Grid>
            ))}
        </Grid>
    );
};

export default Testimonials;
