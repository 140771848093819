export const CUSTOMER_OPINIONS =
    [
        {
            title: "Repolarización Capilar",
            testimonial: "Después de tanto teñir mi cabello, estaba seco y sin vida, ¡pero la repolarización capilar fue mi salvación! Desde el primer tratamiento, noté mi pelo más fuerte, brillante y lleno de vida. Parece increíble cómo este procedimiento puede reparar tanto daño en tan poco tiempo. Ahora mi melena se ve saludable y se siente increíble al tacto. Si tu cabello está dañado y necesitas una transformación urgente, ¡no dudes en probar este tratamiento! Te encantará.",
            rows: 1, cols: 1,
            keywords: 'maquillaje boda',
            description: '',
            img: require('./img/p01.jpg')
        },
        {
            index: 0,
            title: "Keratina Capilar",
            testimonial: "¡No puedo estar más feliz con los resultados de la aplicación de keratina! Mi cabello era rebelde y con frizz, pero después del tratamiento, luce completamente liso, brillante y suave. Lo mejor es que no necesito pasar horas alisándolo, ¡ahora se ve perfecto con tan solo secarlo! Si estás buscando un cambio radical para tener un cabello sedoso y fácil de manejar, este tratamiento es lo mejor que puedes hacer. ¡Me siento como una nueva persona!",
            rows: 1,
            cols: 1,
            keywords: 'maquillaje boda',
            description: '',
            img: require('./img/p02.jpg')
        },
    ];